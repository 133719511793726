import React, {Component} from 'react'
import PhoneNumber from 'awesome-phonenumber'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {Display} from './Display'
import './Forms.css'

export class FormContainer extends Component {

/*
 * Generic Form container
 *  requires props :
 *    - values :             Object datas
 *                           ex: {id:0, name: "..."}
 *    - mandatoryFields :    List of required fields with tets function and state
 *                           ex: {name : { test : f => {return f === '', state : false}}}
 *    - onEdit :             callback to set parent's edition mode
 *    - onUpdate :           calback to ask for parent update
 *    - onExit :             calback to inform parent of the end of the form
 *    - getValues :          callback getting object value
 *    - setValues :          callback saving object values
 *    - checkValues :        callback checking values, force close if it returns false
 */

  constructor (props) {
    super (props)

    this.state = {
      editMode: false,
      warning: false,
      error: false,
      errorMessage: "",
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleAbort = this.handleAbort.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleSaveAndClose = this.handleSaveAndClose.bind(this)

    this.browserTimer = null
    this.browserTimeout = 500
    this.browse = this.browse.bind(this)
    this.handleFirst = this.handleFirst.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleLast = this.handleLast.bind(this)

    this.handleEdit = this.handleEdit.bind(this)
    this.checkMandatoryFields = this.checkMandatoryFields.bind(this)
  }

  /*
   *  Static class method for date I18N
   *  Currently implemented locales are :
   *    fr
   */
  static localDate (locale) {
    const localeDates = {
      fr : {
        firstDayOfWeek: 1,
        today: "aujourd'hui",
        clear: "Effacer",
        dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
        dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        dayNamesMin: ["D", "L", "Ma", "Me", "J", "V", "S"],
        monthNames: ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
        monthNamesShort: ["jan", "fev", "mar", "avr", "mai", "juin", "juil", "aou", "sep", "oct", "nov", "dec"]
      }
    }
    if ( locale in localeDates) {
      return localeDates[locale]
    }
    return null
  }

  /*
   * Enter edition mode :
   *  Make current data backup
   *  Update state and parent mode
   */
  handleEdit () {
    this.setState ({editMode: {state: true}})
  }

  /*
   * Close the form
   */
  async handleClose () {

    /* Check if we are editing the object */
    if (this.state.editMode) {

      /* Ask for recording data before closing */
      this.setState({warning: true})
      return false
    }

    /* Return to the parent view */
    this.props.onExit ()
    return true
  }

  /*
   *  Return to parent view without saving any data
   */
  handleAbort () {
    this.props.onExit ()
  }

  /*
   * Cancel edition
   */
  async handleCancel () {
    /* If this is a new object, quit form view */
    if (this.props.values.id === '0') {
      this.handleAbort ()
      return false
    }
    /* Else, restaure precedent values and close edition mode */
    await this.setState ({editMode: false})
    await this.props.getValues ()
    /* Call the checkMandatoryFields reset states with restaured values */
    this.checkMandatoryFields()
    this.props.onUpdate ()
  }

  /*
   *  Save currend values to parent object
   */
  async handleSave() {
    /* Do not proceed if any required field is missing */
    if ( ! await this.checkMandatoryFields() ) {
      return false
    }
    /* Save data */
    try {
      /* Save the object values */
      if ( await this.props.setValues () ) {
        /* Update edition mode */
        await this.setState({editMode: false})
      }
      return true
    /* Handle errors */
    } catch (e) {
      await this.setState (
        {
          error: true,
          errorMessage: e.message || e
        }
      )
      return false
    }
  }


  /*
   * Save values then close the form
   */
  async handleSaveAndClose () {
    if ( await this.handleSave() ) {
      return await this.handleClose()
    } else {
      await this.setState({warning: false})
    }
    return false
  }

  /*
   *  Check any required fieds and update status
   */
  checkMandatoryFields() {
    let complete = true
    if (this.props.mandatoryFields) {
      // FIXME : eslint bug #791 https://github.com/babel/babel-eslint/issues/791
      // eslint-disable-next-line
      for ( const [field, check] of Object.entries(this.props.mandatoryFields) ) {
        const state = check.test(this.props.values[field])
        this.props.mandatoryFields[field].state = ! state
        complete = complete && state
      }
    }
    /* Return false is any field is missing */
    return complete
  }

  /*
   * Handle browsing over item list
   */
  handleFirst () {
    if (this.browserTimer) {
      clearTimeout (this.browserTimer)
      this.browserTimer = null
    }
    this.props.browser.current = 0
    this.browserTimer = setTimeout(this.browse, this.browserTimeout)
    this.forceUpdate ()
  }

  handlePrev () {
    if (this.browserTimer) {
      clearTimeout (this.browserTimer)
      this.browserTimer = null
    }
    if ( this.props.browser.current > 0 ) {
      this.props.browser.current--
    } else {
      this.props.browser.current = 0
    }
    this.browserTimer = setTimeout(this.browse, this.browserTimeout)
    this.forceUpdate ()
  }

  handleNext () {
    if (this.browserTimer) {
      clearTimeout (this.browserTimer)
      this.browserTimer = null
    }
    if ( this.props.browser.current < this.props.browser.number ) {
      this.props.browser.current++
    } else {
      this.props.browser.current = this.props.browser.number - 1
    }
    this.browserTimer = setTimeout(this.browse, this.browserTimeout)
    this.forceUpdate ()
  }

  handleLast () {
    if (this.browserTimer) {
      clearTimeout (this.browserTimer)
      this.browserTimer = null
    }
    this.props.browser.current = this.props.browser.number - 1
    this.browserTimer = setTimeout(this.browse, this.browserTimeout)
    this.forceUpdate ()
  }

  async browse () {
    const itemList = await this.props.browser.get (this.props.browser.current)
    if (!itemList.totalRecords) {
      this.props.browser.current = -1
      this.props.browser.number = 0
      this.forceUpdate ()
      return
    }
    if (itemList.values===undefined || itemList.values.length===0) {
      this.props.browser.current = itemList.totalRecords - 1
      this.props.browser.number = itemList.totalRecords
      this.browse ()
      return
    }
    const item = itemList.values[0]
    this.props.browser.number = itemList.totalRecords
    this.props.onBrowse (item, this.props.browser)
  }

  /*
   *  Static class method handling any required string
   */
  static checkNotEmpty (candidate) {
    return (candidate !== "" && candidate !== null)
  }

  /*
   *  Static class method handling Icond
   */
  static checkIcon (candidate, optional=false) {
    candidate = candidate.trim().toLowerCase().replace(/\s+/g, ' ')
    return candidate.match (/^[-a-z ]+$/) || ( optional && candidate === "" )
  }

  /*
   *  Static class method handling french phone numbers validation
   */
  static checkFrenchPhoneNumber (candidate, optional=false) {
    candidate = candidate.replace (/[.\s-]/g, '')
    return candidate.match (/^(0|(\+33(\(0\))?)|(0033))[1-9][0-9]{8}$/) || ( optional && candidate === "" )
  }

  static checkIntlPhoneNumber (candidate, optional=false) {
    // Remove decorations and spaces
    candidate = candidate.replace (/[.\s-]/g, '')

    // If candidate is empty, return it's optional value
    if ( candidate === "" ) {
      return optional
    }

    // Look for regional code
    let regionalCode = ""
    if ( candidate[0] === '+' ) {
      regionalCode = PhoneNumber (candidate).getRegionCode()
    }
    // If not found use the default navigator language code
    if ( ! regionalCode ) {
      regionalCode = (navigator.userLanguage || navigator.language).slice(-2).toUpperCase ()
    }

    const phoneNumber = new PhoneNumber(candidate, regionalCode)
    return phoneNumber.isValid()
      /*
    console.log ('Is', candidate, 'a valid ', regionalCode, ' phone number ?', is_valid)
    if ( is_valid ) {
      console.log ('National :', phoneNumber.getNumber('national'))
      console.log ('International :', phoneNumber.getNumber('international'))
      console.log ('rfc3966 :', phoneNumber.getNumber('rfc3966'))
      console.log ('FR :', phoneNumber.getNumberFrom('FR'))
    }
    return false
    */
  }

  /*
   *  Static class method handling Euro currency values
   */
  static checkEuro (candidate, optional=false) {
    candidate = String (candidate).replace (/\s/g, '')
    return candidate.match (/^[+-]?\d+([.,]\d+)?€?$/) || ( optional && candidate === "" )
  }

  /*
   *  Static class method handling floating number values
   */
  static checkNumber (candidate, optional=false) {
    candidate = String (candidate).replace (/\s/g, '')
    return candidate.match (/^[+-]?\d+([.,]\d+)?$/) || ( optional && candidate === "" )
  }

  /*
   *  Static class method handling year
   */
  static checkYear (candidate, optional=false) {
    candidate = String (candidate).replace (/\s/g, '')
    const value = parseInt (candidate)
    const currentYear = new Date().getFullYear()
    return ( optional && candidate === "" ) || ( candidate.match (/^\d+$/) && value > currentYear - 100 && value < currentYear + 100 )
  }

  /*
   *  Static class method handling birth date
   */
  static checkBirthYear (candidate, optional=false) {
    candidate = String (candidate).replace (/\s/g, '')
    const value = parseInt (candidate)
    const currentYear = new Date().getFullYear()
    return ( optional && candidate === "" ) || ( candidate.match (/^\d+$/) && value > currentYear - 100 && value <= currentYear )
  }

  /*
   *  Static class method handling e-mail adresses validation
   */
  static checkEmail (candidate, optional=false) {
    return (
      candidate.match (/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
      || ( optional && candidate === "" )
    )
  }

  /*
   *  Static class method handling Url adresses validation
   */
  static checkUrl (candidate, optional=false) {
    return (
      candidate.match (/^(https?:\/\/)?([-a-z0-9]{2,}\.)+[-a-z0-9]{2,}(:\d+)?(\/.*)?$/i)
      || ( optional && candidate === "" )
    )
  }

  /*
   *  Static class method handling zip code validation
   */
  static checkZipCode (candidate, optional=false) {
    candidate = candidate.replace (/[.\s-]/g, '')
    return (
      candidate.match (/^(([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$/i)
      || ( optional && candidate === "" )
    )
  }

  /*
   * Initialise state from initial props
   */
  componentDidMount() {
    /* If object exists get his values */
    if ( this.props.values.id !== '0' ) {
      this.props.getValues ()
    /* If it is a creation go immedialty to edition mode */
    } else {
      this.setState ({editMode: true})
    }
  }

  /*
   * Perform updates if props or state
   */
  async componentDidUpdate(prevProps, prevState) {

    /* If editMode changed
     *  - Update parent
     *  - Lock / Unlock application states (menus)
     */
    if (prevState.editMode !== this.state.editMode) {
      this.props.onEdit && this.props.onEdit (this.state.editMode)
    }

    /* Check if validatoin state is OK if any */
    if ( 'checkValues' in this.props && this.props.checkValues() === false ) {
      this.handleAbort ()
    }
  }

  /*
   * Clear running tasks
   */
  componentWillUnmount () {
    if (this.browserTimer) {
      clearTimeout (this.browserTimer)
      this.browserTimer = null
    }
  }

  /*
   *  Static class method handling edition mode toggeling
   *   -> Must be set into caller constructor :
   *      this.handleEdit = FormContainer.handelEdit.bind (this)
   */
  static handleEdit (editMode) {
    this.setState ({editMode: editMode})
    this.setLockNavigation (editMode.state, "Enregistrez vos modifications avant de partir...")
  }

  /*
   *  Static class method handling Update requests
   *   -> Must be set into caller constructor :
   *      this.handleUpdate = FormContainer.handelUpdate.bind (this)
   */
  static handleUpdate () {
    this.forceUpdate ()
  }

  /*
   *  Static class method handling Form exiting
   *   -> Must be set into caller constructor :
   *      this.handleExit = FormContainer.handelExit.bind (this)
   */
  static async handleExit () {
    await this.setLockNavigation (false)
    await this.context.upRoute ()
  }

  /*
   *  Static class method handling generic InputText changes
   *   -> Must be set into caller constructor :
   *      this.handleInput = FormContainer.handelInput.bind (objectName, this)
   */
  static async handleInputText(o,e) {
    const field = e.target.id
    const value = e.target.value
    await this.setState(
      prevState => {
        prevState[o][field] = value
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling Slider input changes
   *   -> Must be set into caller constructor :
   *      this.handleInput = FormContainer.handelInput.bind (objectName, this)
   */
  static async handleInputSlider(o,e) {
    if ( e.originalEvent !== undefined
      && e.originalEvent.target !== undefined
      && e.originalEvent.target.children !== undefined
      && e.originalEvent.target.children[0] !== undefined
      && e.originalEvent.target.children['0'].id !== undefined
    ) {
      const field = e.originalEvent.target.children['0'].id
      const value = e.value
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling Integer input changes
   *   -> Must be set into caller constructor :
   *      this.handleInput = FormContainer.handelInput.bind (objectName, this)
   */
  static async handleInputInteger(o,e) {
    const field = e.target.id
    const value = e.target.value
    await this.setState(
      prevState => {
        if ( Number.isInteger(value) ) {
          prevState[o][field] = value
        }
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling floating numbers input changes
   *   -> Must be set into caller constructor :
   *      this.handleInput = FormContainer.handelInput.bind (objectName, this)
   */
  static async handleInputNumber(o,e) {
    const field = e.target.id
    const value = e.target.value
    await this.setState(
      prevState => {
        if ( !isNaN(value) ) {
          prevState[o][field] = value
        }
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling generic CheckBoxes changes
   *   -> Must be set into caller constructor :
   *      this.handelCheckBox = FormContainer.handelCheckBox.bind (objectName, this)
   */
  static handleCheckBox(o, e) {
    const field = e.value
    const value = e.target.checked
    this.setState(
      prevState => {
        if ( prevState[o][field] === 't' | prevState[o][field] === 'f'  ) {
          prevState[o][field] = (value ? 't' : 'f')
        } else {
          prevState[o][field] = value
        }
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling generic Calendar changes
   *   -> Must be set into caller constructor :
   *      this.handelCalendar = FormContainer.handelCalendar.bind (objectName, this)
   */
  static handleCalendar (o, e) {
    const field = e.target.id
    const value = e.value
    this.setState (
      prevState => {
        prevState[o][field] = value
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling generic Pictures changes
   *   -> Must be set into caller constructor :
   *      this.handleInput = FormContainer.handelPicture.bind (objectName, this)
   */
  static async handlePicture (o,e) {
    const field = e.target.id
    const value = e.target.value
    let pictureId = null
    const updater = async () => {
      if ( value ) {
        value.realName = field
        pictureId = await this.fileUpload (value, null, null, this.state[o].id)
      } else if ( this.state[o][field] ) {
        await this.deleteAttachment (this.state[o][field])
      }
      return await this.setState(
        prevState => {
          prevState[o][field] = pictureId
          return {
            [o]: prevState[o]
          }
        }
      )
    }
    this.lazyUpdate (field, updater)
  }

  /*
   *  Static class method handling generic Selectors changes
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.handelSelect.bind (objectName, this)
   */
  static handleSelect (o, e) {
    const field = e.target.id
    const value = e.value
    const option = this.state[field] !== undefined ? field : field + '_list'
    let newValue = {value: null}


    if ( e.value !== null ) {
      newValue = this.state[option].reduce ((r, s) => ( s.value === value ? s : r), {value: null})
    }

    this.setState (
      prevState => {
        prevState[o][field] = newValue.value
        return {
          [o]: prevState[o],
        }
      }
    )
  }


  /*
   *  Static class method handling generic External (DB) Selectors changes
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.handelExternalSelect.bind (objectName, this)
   */
  static handleExternalSelect (o, e) {
    const field = e.target.id
    const field_id = field + '_id'
    const value = e.value
    const option = this.state[field] !== undefined ? field : field + '_list'
    let newValue = {id: null, name: ''}
    if ( e.value !== null ) {
      newValue = this.state[option].reduce ((r, s) => ( s.id === value ? s : r), {id: 0})
    }

    this.setState (
      prevState => {
        prevState[o][field_id] = newValue.id
        prevState[o][field] = newValue.name
        return {
          [o]: prevState[o],
        }
      }
    )
  }

  /*
   *  Static class method handling autoComplete inputText changes
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.getSuggestions.bind (this, fieldName)
   *   -> A state autoCompleteFields record must exists :
   *      AutoCompleteFields: {
   *        field: {
   *          suggestions: null,
   *          data: null,
   *          service: Contacts
   *          query: groupList     // optional name of the Api query to call
   *          key: code,           // optional field name to be used as the search key
   *          id: object_id,       // optional field name used to update object id
   *                               // (if the field name is not the one of the object)
   *        },
   *      }
   *
   * Usahe exemple :
   *  <div className="p-col-12 p-md-2">
   *    <label htmlFor="fullQualifiedCompany">Entreprise</label>
   *  </div>
   *  <div className="p-col-12 p-md-6">
   *    <AutoComplete
   *      className="form-input"
   *      placeholder="Entrez quelques lettres du nom de l'entreprise que vous cherchez ..."
   *      autoComplete="off"
   *      inputId="fullQualifiedCompany"
   *      id="fullQualifiedCompany"
   *      value={this.state.contact.fullQualifiedCompany}
   *      onChange={this.handleInputText}
   *      suggestions={this.state.autoCompleteFields.fullQualifiedCompany.suggestions}
   *      completeMethod={FormContainer.getSuggestions.bind (this, 'fullQualifiedCompany')}
   *      onFocus={this.saveAutoComplete}
   *      onBlur={this.cleanUpAutoComplete}
   *      onSelect={this.cleanUpAutoComplete}
   *    />
   *  </div>
   */
  static async getSuggestions (field, event) {

    const maxValues = 5
    const key = this.state.autoCompleteFields[field].key || 'name'
    const query = this.state.autoCompleteFields[field].query || 'list'
    const service = this.state.autoCompleteFields[field].service
    const filters = {[key] : {value : event.query}}

    const objects = await this.apiCall (service, query, 0, maxValues, false, false, filters)
    const suggestions = objects.values.map (c => c[key])

    if ( objects.totalRecords > maxValues ) {
      suggestions.push ("Encore " + (objects.totalRecords - maxValues) + " de plus ...")
    }
    this.setState (
      prevState => {
        prevState.autoCompleteFields[field].suggestions = suggestions
        prevState.autoCompleteFields[field].data = objects
        return {
          autoCompleteFields : prevState.autoCompleteFields
        }
      }
    )
  }




  /*
   *  Static class method handling generic text cleanup
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpText.bind (objectName, this)
   */
  static async cleanUpText (o, e) {
    const field = e.target.id
    const value = e.target.value.trim().replace(/\s+/g, ' ')
    await this.setState(
      prevState => {
        prevState[o][field] = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling Icon cleanUp (Let only authorized chars)
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpIcon.bind (objectName, this)
   */
  static async cleanUpIcon (o, e) {
    const field = e.target.id
    const value = e.target.value.trim().toLowerCase().replace(/\s+/g, ' ').replace(/[^-a-z ]/g, '')
    await this.setState(
      prevState => {
        prevState[o][field] = value
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class methods handling generic AutoComplete field save and cleanup
   */

  static async saveAutoComplete (o, e) {
    const field = e.target.id
    const value = e.target.value
    await this.setState(
      prevState => {
        if ( prevState.autoCompleteFields !== undefined && prevState.autoCompleteFields
          && prevState.autoCompleteFields[field] !== undefined && prevState.autoCompleteFields[field]
        ) {
          prevState.autoCompleteFields[field].saved = value
        }
        return { autoCompleteFields: prevState.autoCompleteFields }
      }
    )
  }

  static async cleanUpAutoComplete (o, e) {
    let field = ""
    let value = ""
    if ( e.originalEvent !== undefined ) {
      /*
       * if used on Select event the target is the <li> just cliecked
       * target = <li>, parent1 = <ul>, parent2 = <div>, parent3 = <span id="our id !"
       */
      field = e.originalEvent.target.parentElement.parentElement.parentElement.id
      value = e.value
    } else {
      /*
       * if used on Blur event the target is the full object
       */
      field = e.target.id
      value = e.target.value
    }

    await this.setState(
      prevState => {

        if ( prevState.autoCompleteFields !== undefined && prevState.autoCompleteFields
          && prevState.autoCompleteFields[field] !== undefined && prevState.autoCompleteFields[field]
          && prevState.autoCompleteFields[field].data !== undefined && prevState.autoCompleteFields[field].data
        ) {

          const field_id = prevState.autoCompleteFields[field].id ?? field + '_id'
          const fieldName = prevState.autoCompleteFields[field].key ?? 'name'
          const data = prevState.autoCompleteFields[field].data.values.filter (i => i[fieldName] === value)

          if ( data && data.length === 1 ) {
            prevState.autoCompleteFields[field].saved = prevState[o][field]
            prevState[o][field_id] = data[0].id
          } else {
            if ( prevState.autoCompleteFields[field].saved ) {
              prevState[o][field] = prevState.autoCompleteFields[field].saved
            } else {
              prevState[o][field] = ""
              prevState[o][field_id] = 0
            }
          }
        }

        return { [o]: prevState[o] }
      }
    )
  }

  /*
   *  Static class method handling first Name
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpFirstName.bind (objectName, this)
   */
  static async cleanUpFirstName (o, e) {
    const field = e.target.id
    const value = e.target.value.trim().replace(/\s+/g, ' ').replace(/\s?[-_,;]\s?/g, '-')
                  .toLowerCase()
                  .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                  .split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-')
    await this.setState(
      prevState => {
        prevState[o][field] = value
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling last name
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpLastName.bind (objectName, this)
   */
  static async cleanUpLastName (o, e) {
    const field = e.target.id
    const value = e.target.value.trim().replace(/\s+/g, ' ')
    await this.setState(
      prevState => {
        prevState[o][field] = value.toUpperCase()
        return {
          [o]: prevState[o]
        }
      }
    )
  }

  /*
   *  Static class method handling french phone numbers
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpFrenchPhoneNumber.bind (objectName, this)
   */
  static async cleanUpFrenchPhoneNumber (o, e) {
    if (FormContainer.checkFrenchPhoneNumber (e.target.value) ) {
      const field = e.target.id
      let value = e.target.value.trim().replace(/[.\s-]/g, '')
      value = '+33 '
            + value.slice (-9, -8) + ' '
            + value.slice (-8, -6) + ' '
            + value.slice (-6, -4) + ' '
            + value.slice (-4, -2) + ' '
            + value.slice (-2)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling french phone numbers Simple flavor
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpFrenchPhoneNumber.bind (objectName, this)
   */
  static async cleanUpSimpleFrenchPhoneNumber (o, e) {
    if (FormContainer.checkFrenchPhoneNumber (e.target.value) ) {
      const field = e.target.id
      let value = e.target.value.trim().replace(/[.\s-]/g, '')
      value = '0'
            + value.slice (-9, -8) + ' '
            + value.slice (-8, -6) + ' '
            + value.slice (-6, -4) + ' '
            + value.slice (-4, -2) + ' '
            + value.slice (-2)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling french phone numbers Strong flavor
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpFrenchPhoneNumber.bind (objectName, this)
   */
  static async cleanUpStrongFrenchPhoneNumber (o, e) {
    if (FormContainer.checkFrenchPhoneNumber (e.target.value) ) {
      const field = e.target.id
      let value = e.target.value.trim().replace(/[.\s-]/g, '')
      value = '+33 (0)'
            + value.slice (-9, -8) + ' '
            + value.slice (-8, -6) + ' '
            + value.slice (-6, -4) + ' '
            + value.slice (-4, -2) + ' '
            + value.slice (-2)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  static async cleanUpIntlPhoneNumber (o, e) {

    // Get destination field
    const field = e.target.id

    //Remove decorations and spaces
    let value = e.target.value.trim().replace(/[.\s-]/g, '')

    // Look for regional code
    let regionalCode = ""
    if ( value[0] === '+' ) {
      regionalCode = PhoneNumber (value).getRegionCode()
    }
    // If not found use the default navigator language code
    if ( ! regionalCode ) {
      regionalCode = (navigator.userLanguage || navigator.language).slice(-2).toUpperCase ()
    }

    const phoneNumber = new PhoneNumber(value, regionalCode)
    if ( phoneNumber.isValid() ) {
      value = phoneNumber.getNumber('international')
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling Emails
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpEmail.bind (objectName, this)
   */
  static async cleanUpEmail (o, e) {
    const field = e.target.id
    const value = e.target.value.trim()
    if ( FormContainer.checkEmail (value) ) {
      await this.setState(
        prevState => {
          prevState[o][field] = value.toLowerCase()
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling Urls
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpUrl.bind (objectName, this)
   */
  static async cleanUpUrl (o, e) {
    const field = e.target.id
    let value = e.target.value.trim()
    if ( FormContainer.checkUrl (value) ) {
      if ( !value.match (/^(https?:\/\/)/i) ) {
        value = 'http://' + value
      }
      await this.setState(
        prevState => {
          prevState[o][field] = value.toLowerCase()
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling zip code
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpZipCode.bind (objectName, this)
   */
  static async cleanUpZipCode (o, e) {
    const field = e.target.id
    const value = e.target.value.replace (/[.\s-]/g, '')
    if ( FormContainer.checkZipCode (value) ) {
      await this.setState(
        prevState => {
          prevState[o][field] = value.slice (0,2) + " " + value.slice (-3)
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling Euro currency values
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpEuro.bind (objectName, this)
   */
  static async cleanUpEuro (o, e) {
    if ( FormContainer.checkEuro (e.target.value) ) {
      const field = e.target.id
      const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
      const amount = parseFloat(String(e.target.value).replace(',','.').replace(/[\s€]/g,''))
      const value = formatter.format(isNaN(amount)?0.0:amount)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling floating number values
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpNumber.bind (objectName, this)
   */
  static async cleanUpNumber (o, e) {
    if ( FormContainer.checkNumber (e.target.value) ) {
      const field = e.target.id
      const formatter = new Intl.NumberFormat('fr-FR')
      const amount = parseFloat(String(e.target.value).replace(',','.').replace(/\s/g,''))
      const value = formatter.format(isNaN(amount)?0.0:amount)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   *  Static class method handling Salary Euro currency values
   *   -> Must be set into caller constructor :
   *      this.handelSelect = FormContainer.cleanUpEuroSalary.bind (objectName, this)
   */
  static async cleanUpEuroSalary (o, e) {
    if ( FormContainer.checkEuro (e.target.value) ) {
      const field = e.target.id
      const formatter = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: 'EUR' })
      const amount = parseFloat(String(e.target.value).replace(',','.').replace(/[\s€]/g,''))
      const value = formatter.format(isNaN(amount)?0.0:amount)
      await this.setState(
        prevState => {
          prevState[o][field] = value
          return {
            [o]: prevState[o]
          }
        }
      )
    }
  }

  /*
   * Edit save and cancel Button rendering
   * Takes 4/12 horizontal space in top buttons bar
   */
  render_edit () {
    if (this.state.editMode) {
      return (
        <div className="p-grid p-col-12 p-lg-4" >
          <div className="p-col-12 p-lg-6" >
            <Button className="p-button-success" label="Enregistrer" icon="pi pi-save" onClick={this.handleSave}/>
          </div>

          <Display if={! this.props.noCancel}>
            <div className="p-col-12 p-lg-6" >
              <Button className="p-button-warning" label="Abandonner" icon="pi pi-trash" onClick={this.handleCancel}/>
            </div>
          </Display>
        </div>
      )
    } else {
      return (
        <div className="p-grid p-col-12 p-lg-4" >
          <div className="p-col-12 p-lg-6" >
            <Button
              label="Modifier"
              icon="pi pi-pencil"
              onClick={this.handleEdit}
              disabled={!this.props.onEdit || this.props.locked}
              style={{visibility: this.props.onEdit?'block':'hidden'}}
            />
          </div>
        </div>
      )
    }
  }

  /*
   * Browser buttons renderig
   * Takes 2/12 horizontal space in top buttons bar
   */
  render_browse () {
    if (!this.state.editMode && this.props.browser && this.props.onBrowse && this.props.browser.number) {
      return (
        <div className="p-grid p-col-12 p-lg-4" >
          <div className="p-col-12">
            <div className="p-grid p-col-12 p-justify-center" >
              {this.props.browser.current > 0 &&
              <div className="form-input-item-counter">
                <Button icon="pi pi-angle-double-left" onClick={this.handleFirst}/>
              </div>
              }
              {this.props.browser.current > 0 &&
              <div>
                <Button icon="pi pi-angle-left" onClick={this.handlePrev}/>
              </div>
              }
              <div className="form-input-item-counter">
                <Button
                  className="p-button-secondary"
                  label={(this.props.browser.current + 1) + " / " + this.props.browser.number}
                  disabled={true}
                  onClick={() => {return}}
                />
              </div>
              {this.props.browser.current + 1 < this.props.browser.number &&
              <div>
                <Button icon="pi pi-angle-right" onClick={this.handleNext}/>
              </div>
              }
              {this.props.browser.current + 1 < this.props.browser.number &&
              <div className="form-input-item-counter">
                <Button icon="pi pi-angle-double-right" onClick={this.handleLast}/>
              </div>
              }
            </div>
          </div>
        </div>
      )
    }
  }

  /*
   * Close Button rendering
   * Takes 4/12 horizontal space in top buttons bar
   */
  render_close () {
    if (this.state.editMode) {
      return (
        <div className="p-grid p-col-12 p-lg-4 p-justify-end" >
          <div className="p-col-12 p-lg-6">
            <Button className="p-button-danger" label="Fermer" icon="pi pi-times" onClick={this.handleClose} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="p-grid p-col-12 p-lg-4 p-justify-end" >
          <div className="p-col-12 p-lg-6">
            <Button label="Fermer" icon="pi pi-times" onClick={this.handleClose} />
          </div>
        </div>
      )
    }
  }

  /*
   * Warning : Save before close modal rendering
   */
  render_warning () {
    const dialogFooter = (
      <div>
          <Button icon="pi pi-check" onClick={this.handleSaveAndClose} label="Oui" className="p-button-success" />
          <Button icon="pi pi-times" onClick={this.handleAbort} label="Non" className="p-button-danger" />
      </div>
    )
    return (
      <Dialog
        header="Modifications non prises en compte."
        visible={this.state.warning}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({warning:false})}
      >
        <p>Souhaitez-vous enregistrer vos modificatoins ?</p>
      </Dialog>
    )
  }

  /*
   * Error : Something goas wrong !
   */
  render_error () {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({error:false, warning:false})} label="Ok" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header="Enregistrement impossible."
        visible={this.state.error}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => this.setState({error:false, warning:false})}
      >
        <p>{this.state.errorMessage}</p>
      </Dialog>
    )
  }

  /*
   * Main rendering
   */
  render() {
    return (
      <div>
        <div className="p-grid p-justify-between">
          {this.render_edit()}
          {this.render_browse()}
          {this.render_close()}
        </div>
        {this.render_warning()}
        {this.render_error()}
        {this.props.children}
      </div>
    )
  }
}
