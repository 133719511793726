import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'
import WordList from './WordList'
import WordForm from './WordForm'

export class Words extends AuthenticatedComponent {
  render() {
    return (
      <Switch>
        <Route exact path='/Mots' component={WordList} />
        <Route path='/Mots/:id' component={WordForm} />
      </Switch>
    )
  }
}
