import {Component} from 'react'
import {AuthContext} from '../../helpers/AuthContext'

class Login extends Component {

  static contextType = AuthContext

  async componentDidMount(){
    if(! this.context.loggedIn()) {
      await this.context.apiCall ('Login', 'login', null, null)
    }
    this.props.history.replace('/')
  }


  render() {
    return null
  }
}

export default Login
