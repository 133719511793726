import React from 'react'
import {AuthenticatedForm} from '../../components/AuthenticatedForm'
import {FormContainer} from '../../components/FormContainer'
import {Display} from '../../components/Display'
import {MandatoryLabel} from '../../components/MandatoryLabel'
import {InputText} from 'primereact/inputtext'
import {WordTable} from './WordTable'
import '../../components/Forms.css'

class WordForm extends AuthenticatedForm {

  constructor (props) {
    super (
      props, 'word', '/Mots', 'Words',

      {

        word: {
          id: props.match.params.id,
          french: "",
          hebrew: "",
          phonetics: "",
          root: "",
          related_id: 0,
        },

        mandatoryFields: {
          french: {test: FormContainer.checkNotEmpty, state: false},
          hebrew: {test: FormContainer.checkNotEmpty, state: false},
        },

      }
    )
  }

  async handleAdd (id) {
    await super.handleAdd ([id, this.state.word.related_id])
    return this.getValues ()
  }

  async handleRemove () {
    await super.handleRemove (this.state.word.related_id)
    return this.context.updateRoute (this.state.word.id)
  }


  doRender() {
    console.log (!this.state.profile.admin?"LAMBDA":"ADMIN")
    return (
      <div className="p-fluid">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <h1>Mot {this.state.word.french} {this.state.word.hebrew}</h1>
                <FormContainer
                  values={this.state.word}
                  mandatoryFields={this.state.mandatoryFields}
                  onEdit={!this.state.profile.admin?null:this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onExit={this.handleExit}
                  setValues={this.setValues}
                  getValues={this.getValues}
                  browser={this.state.browser}
                  onBrowse={this.handleBrowse}
                >

                  <div className="form-new-line p-grid">

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="french" isMissing={this.state.mandatoryFields.french.state}>Français</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="french"
                          autoFocus={true}
                          value={this.state.word.french}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <MandatoryLabel htmlFor="hebrew" isMissing={this.state.mandatoryFields.hebrew.state}>עברית</MandatoryLabel>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="hebrew"
                          autoFocus={true}
                          value={this.state.word.hebrew}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label htmlFor="phonetics">Phonétique</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="phonetics"
                          autoFocus={true}
                          value={this.state.word.phonetics}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-grid p-justify-even">
                      <div className="p-col-12 p-md-2">
                        <label htmlFor="root">Racine</label>
                      </div>
                      <div className="p-col-12 p-md-6">
                        <InputText
                          className="form-input"
                          disabled={!this.state.editMode}
                          id="root"
                          autoFocus={true}
                          value={this.state.word.root}
                          onChange={this.handleInputText}
                        />
                      </div>
                    </div>

                    <Display if={typeof (this.state.word.id) !== 'string'}>
                      <div className="form-new-line p-col-12 p-grid p-justify-even">
                        <div className="p-col-12 p-md-3">
                          <label>Mots similaires</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                        </div>
                        <div className="p-col-12 p-md-10">
                          <WordTable
                            noAutoFocus={this.state.editMode}
                            relatedId={this.state.word.related_id}
                            selfId={this.state.word.id}
                            locked={this.state.editMode}
                            getContext={this.getContext}
                            onAdd={this.handleAdd}
                            onRemove={this.handleRemove}
                            watch={this.state.word.related_id}
                          />
                        </div>
                      </div>
                    </Display>

                  </div>

                </FormContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WordForm
