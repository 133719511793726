import {Component} from 'react'
import {AuthContext} from '../helpers/AuthContext'

/*
 * Generic component providing global auth and context services
 * to all other childs components
 */


export class AuthenticatedComponent extends Component {

  /* Register context hook */
  static contextType = AuthContext

  /* Constructor profile's default values */
  constructor(props, objectName, pathName, serviceName) {
    super(props)
    this.state = {            // Generic states may be ovewriten by childeren ...
      profile: {id: null},
      profileVersion: 0,
      profileReload: null,
      lockNavigation: false,

      mailing: {
        open: false,
        sort: null,
        filter: null,
        filters: null,
        mailTo: '',
      }
    }

    /*
     * Generic Object helpers
     */
    this.objectName = objectName || 'generic'
    this.pathName = (pathName || 'generic').replace(/\/*$/, '/')
    this.serviceName = serviceName || 'generic'
    this.className = this.constructor.name
    this.getObjectFromId = this.getObjectFromId.bind(this)

    /*
     * Application level objects, routes and menues
     */
    this.appRegister = this.appRegister.bind(this)

    /*
     * Api handlers
     */
    this.runningAsynchronousApiCalls = []
    this.referenceApiCall = this.referenceApiCall.bind (this)
    this.setLockNavigation = this.setLockNavigation.bind (this)
    this.reload = this.reload.bind(this)
    this.apiCall = this.apiCall.bind (this)
    this.cleanApiCalls = this.cleanApiCalls.bind (this)

    /*
     * Attachments handlers
     */
    this.getAttachmentsValues = this.getAttachmentsValues.bind(this)
    this.getAttachmentsValuesFromObject = this.getAttachmentsValuesFromObject.bind(this)
    this.getAttachmentVersions = this.getAttachmentVersions.bind(this)
    this.setAttachmentVersions = this.setAttachmentVersions.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
    this.fileDownload = this.fileDownload.bind(this)

    /*
     * Mailing handlers
     */
    this.openMailing = this.openMailing.bind(this)
    this.closeMailing = this.closeMailing.bind(this)
    this.enableMailing = this.enableMailing.bind(this)
    this.sendMailing = this.sendMailing.bind(this)
    this.mailingTemplateList = this.mailingTemplateList.bind(this)
    this.mailingTemplateGet = this.mailingTemplateGet.bind(this)

    /*
     * Picture componenent helper
     */
    this.getPicture = this.getPicture.bind (this)
  }

  /* LifeCycle generic componentDidMount
   * - Set routerHistoryRegister state
   * - Check if user us logged in (redirect to login page if not)
   * - Store profile and profile's version into the object state
   * - Set object context if one exists
   * - Load external values if any
   */
  async componentDidMount() {
    this.context.routerHistoryRegister (this.props.history)
    if (!this.context.loggedIn()) {
      this.context.setRoute('/login')
      return false
    }
    await this.setState (
      {
        profile: this.context.getProfile(),
        profileVersion : this.context.profileVersion
      },
      async () => {
        if ( this.setContext ) {
          if ( this.context ) {
            const context = await this.context.get ()
            if ( context ) {
              await this.setContext (context)
            }
          }
        }
        await this.getExternalValues ()
      }
    )
    return true
  }

  /* LifeCycle generic componentDidUpdate
   * - Check if user is logged in (redirect to login page if not)
   * - Update context if context's version changed
   * - Reload external values if context changed
   */
  componentDidUpdate(prevProps, prevState) {
    if (!this.context.loggedIn()) {
      this.context.setRoute('/login')
      return false
    }
    if ( prevState.profile && prevState.profileVersion !== this.context.profileVersion ) {
      return this.setState (
        {
          profile: this.context.getProfile(),
          profileVersion : this.context.profileVersion
        },
        this.getExternalValues
      )
    }
    if (this.state.profileReload !== null) {
      const newId = this.state.profileReload
      return this.setState (
        {
          profileReload: null
        },
        () => {
          this.context.updateRoute (newId)
        }
      )
    }
    return true
  }


  /* LifeCycle generic componentWillUnmount
   * - Reset navigation locks if any
   * - Gently abort running asynchronous calls if anny
   */
  componentWillUnmount () {
    if (this.context.lockNavigation && this.state.lockNavigation) {
      this.setLockNavigation (false)
    }
    if ( this.state.browser && this.state.browser.clean ) {
      this.state.browser.clean ()
    }
    this.cleanApiCalls ()
  }

  /*
   * Locks navigation while critical process is running
   * Typically prevents changing page while editing forms
   */
  setLockNavigation (lock, message = "") {
    this.setState ({lockNavigation: lock})
    this.context.setLockNavigation (lock, message)
  }

  /* Asynchonous API cal referencement callback
   * Register all running XHR calls for this object
   */
  referenceApiCall (XHRRef) {
    this.runningAsynchronousApiCalls.push (XHRRef)
    // Cleanup the cleanner => Free "done" xhr references
    this.runningAsynchronousApiCalls = this.runningAsynchronousApiCalls.filter (xhr => xhr.readyState !== 4)
  }

  /* Abort any pending XHR Api Call */
  cleanApiCalls () {
    for (let i=0; i<this.runningAsynchronousApiCalls.length; i++) {
      if ( this.runningAsynchronousApiCalls[i].readyState !== 4 ) {
        this.runningAsynchronousApiCalls[i].abort ()
      }
    }
  }

  /* API Call method including XHR auto referencement */
  async apiCall (...args) {
    const data = await this.context.apiReferencedCall (this.referenceApiCall, ...args)
    return data
  }

  /* Place holder for optional getExternalValues method */
  getExternalValues () {
    return true
  }

  /* Utility function to initialise return values */
  defaultValues (args = []) {
    return {
      totalRecords: 0,
      values: [],
      args: args,
    }
  }

  /* Utility function to set filters */
  defaultFilters (filters=null, defaults = null) {
    if ( ! filters ) {
      filters = {}
    }
    if ( defaults ) {
      filters = { ...defaults, ...filters }
    }
    return filters
  }

  /*
   * Utility function retrieve object informations from it's ID
   */
  async getObjectFromId (id) {

    // Prevents errors when object are not fully loaded
    if ( !id || id === '0' ) {
      return {}
    }

    // Retrieve ORM object informations from item id
    //  objectClass = ORM object ClassName (ex: Company)
    //  objectType = GenericObject type (ex: company)
    //  service = Lowarecase API service name  (ex: companies)
    //  commonName = human full name of the Item ("ex: Microsoft corporation")
    //  inChargeId = is of the object's owner
    const objectInfos = await this.apiCall ( 'Events', 'getObjectInfos', id)

    return {
      objectClass: objectInfos.objectClass,
      objectType: objectInfos.objectType,
      eventClasses: objectInfos.eventClasses,
      service: objectInfos.service,
      itemName: objectInfos.commonName,
      inChargeId: objectInfos.inChargeId
    }
  }

  /* Trigger object data reload */
  reload (objectId=0) {
    this.setState ({profileReload: objectId})
  }

  /*
   * Get Global Application parameters from API
   *
   */
  async getGlobalParameters () {
    return await this.apiCall ( 'Parameters', 'get')
  }

  /*
   * Set Global Application parameters using API
   *
   */
  async setGlobalParameters (parameters={}) {
    return await this.apiCall ( 'Parameters', 'save', parameters)
  }

  /* Register application entry into global context (All are optional)
   * -  name:         // Human name of this item - ex: Open this "événement"
   * -  menu:         // Item menu path
   * -  icon:         // Item menu icon
   * -  route:        // Route activating this item
   * -  service:      // Item's service API name
   * -  orm:          // Item's ORM name (can be retrieves from any API service with only the Item id)
   * -  component:    // Javascript component implementting this item
   * -  default :     // If set, indcate that this item is the default application page
   * -  access :      // Item access validation function (returns true or false)
   */
  async appRegister (entry) {
    await this.context.menuRegister (entry)     // Register menu entries parametres
    await this.context.routeRegister (entry)    // Register routes parameters
    await this.context.serviceRegister (entry)  // Register services parameters
    return true
  }

  /* returns curent up to date application menu */
  async getAppMenu () {
    return await this.context.menuGet ()
  }

  /* returns current application routes */
  getAppRoutes () {
    return this.context.routesGet ()
  }

  /* returns current application default route */
  getAppDefaultRoute () {
    return this.context.defaultRouteGet ()
  }

  /* returns current service parameters from its API name */
  getServiceParameters (service) {
    return this.context.serviceGetByName (service)
  }

  /* returns current service parameter from its ORM table name */
  getServiceParametersFromORM (table) {
    return this.context.serviceGetByTableName (table)
  }

  /*
   * Attachments methods
   */

  /*
   * Get attachements relateds to a provided object_id or to the current object id
   * service parameter is not used, only privided for API compatibility with getAttachmentsValuesFromObject
   */
  getAttachmentsValues (first=0, rows=0, sort=null, filter=null, object_id=null, service=null) {
    const filters = {
      object_id: {value : object_id ?? this.state[this.objectName].id}
    }
    return this.apiCall ('Attachments', 'list', first, rows, sort, filter, filters)
  }

  /*
   * Get attachements list from a provided object_id or from the current object id
   * then, get all attachements related to the returned list
   */
  async getAttachmentsValuesFromObject (first=0, rows=0, sort=null, filter=null, object_id=null, service=null) {
    object_id = object_id ?? this.state[this.objectName].id
    service = service ?? this.serviceName
    /*
    const attachements = {
      totalRecords: 0,
      values: []
    }
    */
    const attachementList = await this.apiCall (service, 'getAttachementList', object_id)
    const filters = {
      object_list: {value : attachementList}
    }
    return this.apiCall ('Attachments', 'list', first, rows, sort, filter, filters)
    /*
    for (let a=0; a<attachementList.length; a++) {
      const attachementPart = await this.getAttachmentsValues (first, rows, sort, filter, attachementList[a])
      attachements.totalRecords += attachementPart.totalRecords
      attachements.values = [...attachements.values, ...attachementPart.values]
    }
    return attachements
    */
  }

  async getAttachmentVersions (attachmentId) {
    const attachmentVersions = await this.apiCall ('Attachments', 'get', attachmentId)
    return attachmentVersions
  }

  async setAttachmentVersions (attachmentItem) {
    const attachmentVersions = await this.apiCall ('Attachments', 'setCurrent', attachmentItem)
    return attachmentVersions
  }

  async deleteAttachment (attachmentId) {
    return await this.apiCall ('Attachments', 'delete', attachmentId)
  }


  async fileUpload (file, handleRequest, handleProgress, object_id=null) {

    // Helper closure compute global progress based on the total attachment number
    function chunkHandleProgress (handleProgress, currentChunk, totalChunks) {
      return (event) => {
        if ( handleProgress && event.lengthComputable) {
          const progress = event.loaded / event.total
          handleProgress (
            {
              lengthComputable: event.lengthComputable,
              total: totalChunks,
              loaded: currentChunk + progress
            }
          )
        }
      }
    }

    // Compute chunks based on config file
    const chunkSize = this.context.config.attachments.chunkSize
    const totalChunks = Math.ceil(file.size / chunkSize)

    // Uplaod each chukn until the whole file is sent
    let uploaded = ''
    for ( let i=0; i<totalChunks; i++ ) {
      const currentChunk = i+1
      const chunk = file.slice (i*chunkSize, currentChunk*chunkSize, file.type)
      chunk.name = file.realName ?? file.name
      uploaded = await this.context.upload (
        chunk,
        handleRequest,
        chunkHandleProgress (handleProgress, currentChunk, totalChunks),
        object_id ?? this.state[this.objectName].id,
        currentChunk,
        totalChunks,
        uploaded
      )
    }

    // Return the attachment id
    return uploaded
  }

  fileDownload (file, handleRequest=null, handleProgress=null) {
    return this.context.download (file, handleRequest, handleProgress)
  }


  /*
   * Mailing methods
   */

  /*
   * Helper return true if mailing is possible for an object set
   * defined by criterions
   * TODO : Implement not page olny logic !
   */
  enableMailing (objects, sort, filter, filters) {
    if ( objects === null ) {
      // In case of mailingToPageOnly=false, when mailing may apply to the whole data set
      // this is where you should use sort, filter and filters parameters to query API in
      // order to find out if mailing is ok or not.
      // Or may be retrieve object list at this momenent ...
      // TODO
      console.log ('*** WARNING : Missing logic to handle not page only check !!')
      return false
    }
    for (let i=0; i<objects.length; i++) {
      if (objects[i].eMail && !objects[i].noMailing) {
        return true
      }
    }
    return false
  }

  /*
   * Helper : Set mailing state in open mode
   */
  openMailing (sort, filter, filters, mailTo=null) {
    this.setState (
      {
        mailing: {
          open: true,
          sort: sort,
          filter: filter,
          filters: filters,
          mailTo: mailTo,
        }
      }
    )
  }

  /*
   * Helper : Set mailing state in closed mode
   */
  closeMailing () {
    this.setState (
      {
        mailing: {
          open: false,
          sort: null,
          filter: null,
          filters: null,
          mailTo: '',
        }
      }
    )
  }

  /*
   * Helper : Get Mailing Template list
   */
  async mailingTemplateList () {
    const template_list = await this.apiCall ('MailingTemplates', 'list')
    return template_list.values.map (t => ({ label: t.subject, value: t.id }))
  }

  /*
   * Helper : Get template content
   */
  mailingTemplateGet (templateId) {
    const template = this.apiCall ('MailingTemplates', 'get', templateId)
    return template
  }


  /*
   * Send mailing
   *  - Create new mailing object and get its id
   *  - Inject attachments into the mailing object
   *  - Call current service mailling method to actually process
   *    the mass mailing
   */
  async sendMailing (mailing, xhrHandler=null, handleProgress=null) {

    if (mailing) {
      function globalXhrHandler (xhrHandler, terminate) {
        return (xhr) => {
          xhrHandler (
            {
              abort: () => {
                xhr.abort ()
                terminate ()
              }
            }
          )
        }
      }

      // Helper closure compute global progress based on the total attachment number
      function globalHandleProgress (handleProgress, currentAttachment, totalAttachments) {
        return (event) => {
          if (event.lengthComputable) {
            const progress = event.loaded / event.total
            handleProgress (
              {
                lengthComputable: event.lengthComputable,
                total: totalAttachments,
                loaded: currentAttachment + progress
              }
            )
          }
        }
      }

      // Store attachments localy but do not send them via Api call !
      const attachments = mailing.attachments
      mailing.attachments = null
      mailing.id = 0

      // Store the mailing into database and obtain an id
      const mailingId = await this.apiCall ('Mailings', 'save', mailing)


      // Send attachments if any
      for (let i=0; i<attachments.length; i++) {
        const file = attachments[i]
        //fileUpload (file, handleRequest, handleProgress, object_id=null)
        try {
          await this.fileUpload (
            file,
            globalXhrHandler (xhrHandler, this.closeMailing),
            globalHandleProgress (handleProgress, i, attachments.length),
            mailingId
          )
        } catch (e) {
          console.log (e)
        }
      }

      await this.apiCall (
        this.serviceName,
        'mailing',
        0,
        Number.MAX_SAFE_INTEGER,
        this.state.mailing.sort,
        this.state.mailing.filter,
        this.state.mailing.filters,
        mailingId
      )
    }
    this.closeMailing ()
  }


  /*
   * Picture autoloader
   */
  async getPicture (pictureId) {
    if ( pictureId ) {
      const pictures = await this.getAttachmentVersions (pictureId)
      if ( pictures && pictures.length ) {
        const picture = pictures.find (p => p.isCurrent)
        if ( picture && picture.filePathName !== undefined ) {
          const data = await this.fileDownload (picture.filePathName)
          return data
        }
      }
    }
    return null
  }


  /*
   * Rendering methods
   */

  /*
   * Place Holder for doRender method
   * Do not render anything, this will be done into child object
   */
  doRender () {
    return null
  }

  /*
   * Call child custom rendering method
   */
  render() {
    if ( this.state.profile.id ) {
      return this.doRender ()
    }
    return null
  }
}
