import React from 'react'
import {AuthenticatedComponent} from '../../components/AuthenticatedComponent'

export class Footer extends AuthenticatedComponent {

  render() {
    return  (
      <div className="layout-footer">
        <span className="footer-text" style={{'marginRight': '5px'}}> </span>
      </div>
    )
  }

}
