import React, { Component } from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {AuthContext} from '../../helpers/AuthContext'
import './Login.css'

class Logon extends Component {

  constructor(props){
    super(props)
    this.state = {
      username : '',
      password : '',

      alert : false,
      title : '',
      message : '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  static contextType = AuthContext

  async componentDidMount(){
    if( this.context.loggedIn() ) {
      const profile = this.context.getProfile ()
      if (profile.admin) {
        this.props.history.replace('/')
      }
    }
  }

  async handleFormSubmit(event){
    event.preventDefault()
    try {
      this.context.logout (false)
      await this.context.apiCall ('Login', 'login', this.state.username, this.state.password)
      document.location.reload(true)
    } catch(err) {
      // Clear login Token to prevent reload illegal connexion but stay on this page (do not reset)
      this.context.logout (false)
      await this.setState (
        {
          alert : true,
          title : "Echec de l'authentification.",
          message : err,
        }
      )
    }
  }

  handleChange(event){
    this.setState({[event.target.name]: event.target.value})
  }

  render_alert() {
    const dialogFooter = (
      <div>
          <Button onClick={() => this.setState({alert:false}, this.context.logout)} label="Ok" className="p-button" />
      </div>
    )
    return (
      <Dialog
        header={this.state.title}
        visible={this.state.alert}
        modal={true}
        footer={dialogFooter}
        closable={false}
        onHide={() => document.location.reload(true)}
      >
        <p>{this.state.message}</p>
      </Dialog>
    )
  }


  render() {
    console.log ('Sur render :-)')
    try {
      return (
        <div className="login-center">
          <div className="login-card">
            <h1>Hébreu</h1>
            <form onSubmit={this.handleFormSubmit}>
              <input
                className="login-form-item"
                placeholder="Adresse mail ..."
                name="username"
                type="text"
                onChange={this.handleChange}
              />
              <input
                className="login-form-item"
                placeholder="Mot de passe ..."
                name="password"
                type="password"
                onChange={this.handleChange}
              />
              <input
                className="login-form-submit"
                value="CONNEXION"
                type="submit"
              />
            </form>
          </div>
          {this.render_alert()}
        </div>
      )
    } catch (err) {
      this.context.logout ()
    }
  }
}

export default Logon
