import React from 'react'
import {AuthenticatedTable} from '../../components/AuthenticatedTable'
import {LazyDataTable} from '../../components/LazyDataTable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'

export class WordTable extends AuthenticatedTable {

  constructor(props) {
    super(props, 'word', '/Mots', 'Words')

    this.getNewRelatedList = this.getNewRelatedList.bind(this)
  }

  setCustomFilters (filters) {
    if ( this.props.relatedId !== undefined ) {
      filters = this.defaultFilters (filters, {related_id: {value : this.props.relatedId}})
    }
    if ( this.props.selfId !== undefined ) {
      filters = this.defaultFilters (filters, {exclude_self_id: {value: this.props.selfId}})
    }
    return filters
  }

  async getNewRelatedList (template) {
    const maxValues = 5
    const filters = {
      exclude_self_id: {value: this.props.selfId},
      name : {value : template},
    }
    if ( this.props.relatedId ) {
      filters.exclude_related_id = {value: this.props.relatedId}
    }
    return this.apiCall (this.serviceName, 'list', 0, maxValues, null, null, filters)
  }

  removeButton(a) {
    return (
      <Button
        className="p-button-danger"
        label="Dissocier"
        icon="pi pi-times-circle"
        onClick={a}
      />
    )
  }


  doRender() {

    return (
      <LazyDataTable
        ref={this.setRef}
        locked={this.props.locked}

        onOpen={this.handleOpen}

        onCreate={(!this.state.profile.admin || this.props.selfId)?null:this.handleCreate}

        onDelete={(!this.state.profile.admin || this.props.selfId)?null:this.handleDelete}

        maxRowPerPage={this.state.profile.admin}

        onAdd={(!this.state.profile.admin || !this.props.selfId)?null:this.props.onAdd}
        addTitle='Associer'
        addItemGet={this.getNewRelatedList}
        addItemTitle="Mots similaire"
        addItemPrompt="Chercher un mot à associer"

        custom2Template={this.removeButton}
        onCustom2={(!this.state.profile.admin || !this.props.selfId)?null:this.props.onRemove}
        custom2Enabler={() => this.props.relatedId}


        getValues={this.getValues}
        watch={this.state.profile}
      >
        <Column field="french" header="Français" sortable={true} style={{width:'10%'}} />
        <Column field="hebrew" header="עברית" sortable={true} style={{textAlign:'right', width:'10%'}} />
        <Column field="phonetics" header="Phonétique" sortable={true} style={{width:'10%'}} />
        <Column field="root" header="Racine" sortable={true} style={{width:'10%'}} />
      </LazyDataTable>
    )
  }
}

