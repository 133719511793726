import {AuthenticatedComponent} from './AuthenticatedComponent'
import {FormContainer} from './FormContainer'

/*
 * Generic Form component
 */

export class AuthenticatedForm extends AuthenticatedComponent {

  /*
   * Constructor profile's default values
   *  objectName = The object state name eg: company
   *  pathName = The URI path to the object eg: Compagnies
   *  serviceName = The Api service name eg: Companies
   *  state = The specific object state to be added to generic one
   */
  constructor(props, objectName, pathName, serviceName, state = {}) {

    super(props, objectName, pathName, serviceName)

    this.state = {
      ...this.state,              // First, set parent state
      browser: null,              // Next add our own values (may be overwriting parent's ones)
      editMode: false,
      mustReload: false,
      reloadOnChangeFields: [],
      ...state,                   // Last add child values (may be overwriting ours)
    }

    this.lazyUpdates = {}

    /*
     * Forms methods
     */
    this.getValues = this.getValues.bind(this)
    this.setValues = this.setValues.bind(this)
    this.cleanUpValues = this.cleanUpValues.bind(this)
    this.setupNewItem = this.setupNewItem.bind(this)
    this.lazyUpdate = this.lazyUpdate.bind(this)

    /*
     * Contextual data management methods
     */
    this.getContext = this.getContext.bind(this)
    this.setContext = this.setContext.bind(this)
    this.handleBrowse = this.handleBrowse.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAdd = this.handleAdd.bind(this)

    /*
     * Static class methods from FormContainer
     */
    this.handleEdit = FormContainer.handleEdit.bind(this)
    this.handleUpdate = FormContainer.handleUpdate.bind(this)
    this.handleExit = FormContainer.handleExit.bind(this)

    this.handleInputText = FormContainer.handleInputText.bind(this, this.objectName)
    this.handleInputInteger = FormContainer.handleInputInteger.bind(this, this.objectName)
    this.handleInputNumber = FormContainer.handleInputNumber.bind(this, this.objectName)
    this.handleInputSlider = FormContainer.handleInputSlider.bind(this, this.objectName)
    this.handleSelect = FormContainer.handleSelect.bind(this, this.objectName)
    this.handleExternalSelect = FormContainer.handleExternalSelect.bind(this, this.objectName)
    this.handleCheckBox = FormContainer.handleCheckBox.bind(this, this.objectName)
    this.handleCalendar = FormContainer.handleCalendar.bind(this, this.objectName)
    this.handlePicture = FormContainer.handlePicture.bind(this, this.objectName)

    this.cleanUpText = FormContainer.cleanUpText.bind(this, this.objectName)
    this.cleanUpLastName = FormContainer.cleanUpLastName.bind(this, this.objectName)
    this.cleanUpFirstName = FormContainer.cleanUpFirstName.bind(this, this.objectName)
    this.cleanUpEmail = FormContainer.cleanUpEmail.bind(this, this.objectName)
    this.cleanUpUrl = FormContainer.cleanUpUrl.bind(this, this.objectName)
    this.cleanUpZipCode = FormContainer.cleanUpZipCode.bind(this, this.objectName)
    this.cleanUpSimpleFrenchPhoneNumber = FormContainer.cleanUpSimpleFrenchPhoneNumber.bind(this, this.objectName)
    this.cleanUpStrongFrenchPhoneNumber = FormContainer.cleanUpStrongFrenchPhoneNumber.bind(this, this.objectName)
    this.cleanUpIntlPhoneNumber = FormContainer.cleanUpIntlPhoneNumber.bind(this, this.objectName)
    this.saveAutoComplete = FormContainer.saveAutoComplete.bind(this, this.objectName)
    this.cleanUpAutoComplete = FormContainer.cleanUpAutoComplete.bind(this, this.objectName)
    this.cleanUpIcon = FormContainer.cleanUpIcon.bind(this, this.objectName)
    this.cleanUpEuro = FormContainer.cleanUpEuro.bind(this, this.objectName)
    this.cleanUpNumber = FormContainer.cleanUpNumber.bind(this, this.objectName)
    this.cleanUpEuroSalary = FormContainer.cleanUpEuroSalary.bind(this, this.objectName)


  }

  /*
   * Generic get and set forms methods
   */

  async getValues () {
    // Reset lazyUpdates
    this.lazyUpdates = {}
    // Get values
    const values = await this.apiCall (this.serviceName, 'get', this.state[this.objectName].id)
    await this.cleanUpValues (values)
    await this.setState ({[this.objectName]: values})
  }

  /*
   * Place holder for optional cleanup child method
   */
  cleanUpValues (values) {
      return true
  }

  /*
   * Returns default option from option list
   */
  defaultOption (option_list) {
    const defaultValue = option_list.values.find (t => t.defaultValue === true)
    if ( defaultValue ) {
      return defaultValue
    }
    return {id: 0, name: ''}
  }

  /*
   * Check for lazyUpdate callback to be run
   */
  async doLazyUpdates () {
    let updated = false
    for (const field in this.lazyUpdates) {
      if ( this.lazyUpdates[field] ) {
        await this.lazyUpdates[field] ()
        updated = true
      }
      this.lazyUpdates = {}
    }
    // If any update have take place, whe need to update the object again
    if ( updated ) {
      return await this.recordValues ()
    }
    return updated
  }

  /*
   * Api call to record object value into database
   */
  async recordValues () {
    // Save values to database
    const newId = await this.apiCall (this.serviceName, 'save', this.state[this.objectName])
    .catch (
      (e) => {
        let [error, value] = e.split (':')

        if ( this.state.constraints !== undefined && this.state.constraints[error] !== undefined ) {
          if ( this.state.constraints[error][value] !== undefined ) {
            if ( typeof this.state.constraints[error][value] === 'string' ) {
              throw (new Error (this.state.constraints[error][value]))
            }
            if ( typeof this.state.constraints[error][value] === 'function' ) {
              throw (new Error (this.state.constraints[error][value]()))
            }
          }
        }
        throw (e)
      }
    )
    return newId
  }

  /*
   * Generic object updater
   * => If state update is needed, must return true
   *    If the component will reload, must return false because
   *    there will left nothing to update after ...
   */
  async setValues () {
    const newId = await this.recordValues ()
    if (this.state[this.objectName].id === '0') {
      await this.setState (
        prevState => {
          prevState[this.objectName].id = newId
            return {
              [this.objectName]: prevState[this.objectName]
            }
        },
        // Do whatever is needed to setup item after it was created ...
        async () => {
          await this.setupNewItem()
          await this.doLazyUpdates ()
          await this.reload (newId)
        }
      )
      return false   // Reload : Don't update
    } else {
      await this.doLazyUpdates ()
      if ( this.state.mustReload ) {
        this.reload (newId)
        return false // Reload : Don't update
      } else {
        this.getExternalValues ()
        return true // No reload : Do update
      }
    }
  }

  /*
   * Register lazy update requests
   */
  lazyUpdate (field=null, updater=null) {
    if ( field ) {
      this.lazyUpdates[field] = updater
    } else {
      this.lazyUpdates = {}
    }
  }

  /*
   * Place holder for optional setup child method
   */
  setupNewItem (newId) {
      return true
  }

  /*
   * Generic add and remove for default Items
   * ie : Items having one obvious default list (groups, users, sets etc...)
   */
  async handleRemove (ids) {
    const result = await this.apiCall (this.serviceName, 'removeItems', this.state[this.objectName].id, ids)
    await this.getExternalValues ()
    return result
  }

  async handleAdd (ids) {
    const result = await this.apiCall (this.serviceName, 'addItems', this.state[this.objectName].id, ids)
    await this.getExternalValues ()
    return result
  }

  /*
   * LyfeCycle Method
   */
  async componentDidUpdate(prevProps, prevState) {
    const ret = await super.componentDidUpdate(prevProps, prevState)
    if ( ! this.state.mustReload ) {
      for (let i=0; i<this.state.reloadOnChangeFields.length; i++ ) {
        if (prevState[this.objectName][this.state.reloadOnChangeFields[i]] !== this.state[this.objectName][this.state.reloadOnChangeFields[i]]) {
          this.setState ({mustReload: true})
          break ;
        }
      }
    }
    return ret
  }

  /*
   * Contextual data management methods
   */

  getContext () {
    const objectForm = this.objectName + 'Form'
    return {
      [objectForm]: {
        browser: this.state.browser,
      }
    }
  }

  async setContext (context) {
    const objectForm = this.objectName + 'Form'
    if ( context && context[objectForm]!==undefined ) {
      if ( context[objectForm].browser !== undefined ) {
        this.setState ({browser: context[objectForm].browser})
      }
    }
    if ( context && context.browser !== undefined ) {
      this.setState ({browser: context.browser})
    }
  }

  handleBrowse (event, browser) {
    return this.context.changeRoute (this.pathName + event.id, {browser: browser})
  }

}
