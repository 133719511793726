import {Words} from '../words/Words'

export class MenuItems {

  static async registerAppComponents () {

    await this.appRegister (
      {
        menu: '/Mots',                     // Item menu path
        icon: 'fa-fw far fa-comment-dots', // Item menu icon
        route: '/Mots',                    // Route activating this item
        component: Words,                  // Javascript component implementting this item
        default: true,                     // This is the default page for the application
      }
    )

    return true
  }
}

